import { useMemo } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { createAction } from "./redux/actions";
import { v4 } from "uuid";

export const useDataProvider = (dataProviderName, multipart = false) => {

    const uid = useMemo(() => v4(), [])

    const dispatch = useDispatch();
    const dataProviderActions = createAction(dataProviderName, uid, multipart)

    const idItems = useSelector(state => state[dataProviderName])?.components[uid]?.items ?? [];
    const globalItems = useSelector(state => state[dataProviderName])?.global.items ?? {};
    const items = Array.isArray(idItems) ? idItems.map(idItem => globalItems[idItem]) : idItems;
    const total = useSelector(state => state[dataProviderName])?.components[uid]?.total ?? 0;
    const loading = useSelector(state => state[dataProviderName])?.components[uid]?.loading ?? null;
    const downloading = useSelector(state => state[dataProviderName])?.components[uid]?.downloading ?? null;
    const saving = useSelector(state => state[dataProviderName])?.components[uid]?.saving ?? null;
    const success = useSelector(state => state[dataProviderName])?.components[uid]?.success ?? null;
    const globalItem = useSelector(state => state[dataProviderName])?.global.item ?? null;
    const itemComponent = useSelector(state => state[dataProviderName])?.components[uid]?.item ?? globalItem
    const item = globalItems[itemComponent] ? { data: globalItems[itemComponent] } : itemComponent;
    const errorMessage = useSelector(state => state[dataProviderName])?.components[uid]?.errorMessage ?? "";
    const errors = useSelector(state => state[dataProviderName])?.components[uid]?.errors ?? {};
    const reloadCounter = useSelector(state => state.reloadCounter || 0)

    const loadAll = (filterModel, sortModel, page, per_page) => {
        return dispatch(dataProviderActions.getAll(filterModel, sortModel, page, per_page))
    }

    const reset = () => {
        dispatch(dataProviderActions.reset())
    }

    const loadOne = (id) => {
        return dispatch(dataProviderActions.getOne(id))
    }

    const getOne = (id) => {
        return  globalItems[id] ?? null; 
    }

    const save = (values) => {
        return dispatch(dataProviderActions.save(values))
    }

    const doAction = (values) => {
        return dispatch(dataProviderActions.doAction(values))
    }

    const destroy = (id) => {
        return dispatch(dataProviderActions.destroy(id))
    }

    const download = () => {
        return dispatch(dataProviderActions.download())
    }

    return {
        // state properties
        uid,
        items,
        total,
        loading,
        saving,
        success,
        item,
        errorMessage,
        errors,
        reloadCounter,

        //
        loadAll,
        reset,
        loadOne,
        getOne,
        save,
        doAction,
        destroy,
        download,
        downloading,

    }
}
