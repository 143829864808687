import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { List as ListComponent, useDrawer, Confirm } from 'src/mtska-frontend-app-component'
import { useGridApiRef } from '@mui/x-data-grid';
import { useDataProvider } from 'src/mtska-frontend-data-provider';

import { AppContext } from 'src/mtska-frontend-app-scaffolding';
import { useLoadAll } from 'src/mtska-frontend-data-provider/hooks/useLoadAll.hooks';
import SplitButton from 'src/mtska-frontend-app-component/components/Button/SplitButton'

const dataProviderName = 'cashflows/cashflowrecord';
const drawerRouteBase = 'cashflowrecords';
const moduleName = "Split Movimento";

const List = (params) => {
    const { t } = useTranslation();
    const { userCapabilities } = useContext(AppContext);
    const { activate } = useDrawer();

    const apiRef = useGridApiRef();
    const getSelected = () => {
        return [...apiRef.current.getSelectedRows()].map(([name]) => name);
    };
    const [selected, setSelected] = useState([]);
    const handleRowSelection = (newSelection) => {
        setSelected(newSelection);
        params.rowSelectionExternalListener && params.rowSelectionExternalListener(newSelection);
    };

    const getFunctionalButtons = () => {
        const functionalButtons = []
        functionalButtons.push(
            <SplitButton
                actions={[
                    {
                        disabled: userCapabilities && !userCapabilities[dataProviderName + '.create'],
                        icon: 'faPlus',
                        label: t("New"),
                        onClick: () => activate(
                            drawerRouteBase + '/create',
                            {
                                detailStack: params.detailStack ?? {},
                                relatedElements: params.relatedElements ?? {}
                            }
                        )
                    },
                    {
                        disabled: userCapabilities && !userCapabilities[dataProviderName + '.import'],
                        icon: 'faFileImport',
                        label: t("Import"),
                        onClick: () => activate(
                            'imports/list',
                            {
                                filters: {
                                    items: [{
                                        operator: 'equals',
                                        field: 'model',
                                        value: dataProviderName,
                                    }]
                                },
                                currentDataProviderName: dataProviderName,
                                moduleName,
                                detailStack: params.detailStack ?? {},
                                relatedElements: params.relatedElements ?? {}
                            }
                        )
                    },
                    {
                        disabled: (userCapabilities && !userCapabilities[dataProviderName + '.create']) || selected.length === 0,
                        icon: 'faFileInvoice',
                        label: t('Manage Account Plan') + ` (${selected.length})`,
                        onClick: ({ resetSelectedIndex }) => activate(
                            'pianoconticfs/list',
                            {
                                massiveActionProps: {
                                    doMassiveAction: ({ selectedItems = [] }) => {
                                        const data = (selected || []).map((id) => ({ id, pianoContiCF: selectedItems[0] }))
                                        const action = 'pianoContiCFManagement'
                                        const model = 'cashflows/cashflowrecord'
                                        doAction({ action, data, model }).then(() => {
                                            apiRef.current.setRowSelectionModel([])
                                            resetSelectedIndex()
                                        })
                                    },
                                    maxRowSelection: 1
                                }
                            }
                        )
                    },
                    {
                        disabled: (userCapabilities && !userCapabilities[dataProviderName + '.delete']) || selected.length === 0,
                        icon: 'faTrash',
                        label: t('Delete') + ` (${selected.length})`,
                        onClick: () => handleDelete()
                    }
                ]}
                initSelectedIndex={0}
                showDivider={selected.length === 0}
            />
        )
        return functionalButtons
    }


    const [confirmDelete, setConfirmDelete] = useState();
    const handleDelete = () => {
        setConfirmDelete(
            <Confirm
                oklabel={t("Delete")}
                okcallable={async () => {
                    doAction && await doAction({ action: 'destroy', model: dataProviderName, data: getSelected() });
                    setConfirmDelete(null);
                    apiRef.current.setRowSelectionModel([]);
                    loadAll();
                }}
                kolabel={t("Cancel")}
                kocallable={() => { setConfirmDelete(null) }}
                title={t("Items elimination")}>
                {t("Are you sure to remove the selected elements?")}
            </Confirm>
        );
    }

    const { doAction, loadAll, items, total, loading, reloadCounter } = useDataProvider(dataProviderName)
    const [listFilterPageSort, setListFilterPageSort] = useState();
    const handleFilterPageSortChange = (model) => {
        setListFilterPageSort(model);
    }

    useLoadAll({ listFilterPageSort, loadAll, params, reloadCounter })

    const handleRowDoubleclick = (row) => {
        activate(drawerRouteBase + '/detail', { id: row.id });
    }

    return (
        <>
            {userCapabilities && userCapabilities[dataProviderName + '.list'] && (
                <ListComponent
                    {...params}
                    onRowDoubleClick={(userCapabilities && userCapabilities[dataProviderName + '.view']) ? handleRowDoubleclick : () => { }}
                    onFilterPageSortChange={handleFilterPageSortChange}
                    items={items}
                    total={total}
                    loading={loading}
                    apiRef={apiRef}
                    enableSelection
                    rightButtons={getFunctionalButtons()}
                    dataProviderName={dataProviderName}
                    title={moduleName}
                    rowSelectionExternalListener={handleRowSelection}
                    doAction={doAction}
                />
            )}
            {confirmDelete}
        </>
    );
};

export default List;


