import React from "react";
import { Box } from "@mui/joy";
import { useString } from "../../utils/String";
import { useDrawer } from "../Drawer";

const drawerRouteBase = 'persons';

const PersonAvatar = ({ firstname, lastname, size = "default", url = null, withname = false, detail = false, onClick, item }) => {

    const apiBasePath =  process.env.REACT_APP_API_ENDPOINT || '';
    const { firstCharacter, toColor } = useString();

    const { activate } = useDrawer();

    const handlePersonPictureClick = () => {
        activate(drawerRouteBase + '/personalPictureAssociation', { person: item, multipart: true });
    }

    return (
        <Box className={"person-avatar-wrapper person-avatar-withname-" + withname} >
            <Box className={"person-avatar person-avatar-" + size} style={{ backgroundColor: toColor(firstname + " " + lastname) }} title={firstname + " " + lastname}  onClick={handlePersonPictureClick}>
                {url ? (
                    <img src={apiBasePath + url} />
                ) : (
                    (firstCharacter(firstname)) + (firstCharacter(lastname))
                    // <img src="https://thispersondoesnotexist.com" />
                )}
            </Box>
            {withname === true && (
                <Box className={"person-name" + (detail && " hasdetail")} onClick={onClick}>
                    <span className="fullname" title={firstname + " " + lastname}>{(firstname + " " + (lastname ?? '')).trim()}</span>
                    {
                        detail && (
                            <span className="detail">{(detail)}</span>
                        )
                    }
                </Box>
            )}
            {withname === 'initial' && (
                <Box className={"person-name"} onClick={onClick}>
                    <span className="name" title={firstname + " " + lastname}>{firstCharacter(firstname) + (lastname && firstCharacter(lastname))}</span>
                </Box>
            )}
        </Box>
    )
}

export default PersonAvatar;