import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDrawer, useFormatter, useRelationshipInjector } from '../../../../mtska-frontend-app-component';
import { useDataProvider } from 'src/mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';
import { CircularProgress } from '@mui/joy';
import {  Backdrop } from "@mui/material"


const dataProviderName = 'cars/trace-km';
const model = 'cars/traceKm';
const modelExposedName = 'Traces';
const wizardAction = 'create';

const CreateOriginal = (params) => {

    const { t } = useTranslation();
    const { close } = useDrawer();
    const { userCapabilities, existingModules } = useContext(AppContext);
    
    const { injectToDetail } = useRelationshipInjector();
    const { formatDate, formatDistance } = useFormatter()
    const [drawable, setDrawable] = useState(false);

    // DATA PROVIDER
    const {
        doAction,
        item,
        loadOne,
        errorMessage,
        errors,
        saving,
        success
    } = useDataProvider(dataProviderName, params.multipart);

    const { item: car, loadOne: loadOneCar } = useDataProvider('cars/car');

    const modelName = existingModules[dataProviderName].modelName
    const detailConfig = {
        "tabs": [
            {
                "name": "create_new_trace_km",
                "fieldsets": [
                    {
                        "name": "vehicle_information",
                        "fields": {
                            "car": {
                                "type": "relationshipSingle",
                                "required": true,
                                "relation": {
                                    "drawerRoute": "cars",
                                    "relationAssociator": "hidden",
                                    "relationDissociator": "hidden",
                                    "detailInspector": "hidden",
                                },
                                "view": "car"
                            },
                            "legalEntity": {
                                "type": "relationshipSingle",
                                "required": true,
                                "relation": {
                                    "drawerRoute": "legalentities",
                                    "relationAssociator": "hidden",
                                    "relationDissociator": "hidden",
                                    "detailInspector": "hidden",
                                    "filter": {
                                        "items": [
                                            {
                                                "field": "type",
                                                "operator": "equals",
                                                "value": "customer"
                                            }
                                        ]
                                    }
                                },
                                "view": "legalEntity"
                            },
                        }
                    },
                    {
                        "name": "trace_data",
                        "fields": {
                            "value": {
                                "type": "number",
                                "required": true,
                                "className": "col-6",
                                "placeholder": "insert",
                                "icon": "faRoad",
                                "label": "value 1"
                            },
                            "traced_at": {
                                "type": "date",
                                "required": true,
                                "className": "col-6",
                                "placeholder": "insert",
                                "icon": "faCalendarStar",
                                "label": "tracing date 1"
                            }
                        }
                    }
                ]
            }
        ],
        "validation": []
    }

    let numberOfTrackFields = params.numberOfTrackFields;
    if (!numberOfTrackFields) numberOfTrackFields = 10;
    for (let i = 2; i <= numberOfTrackFields; i++) {
        detailConfig.tabs[0].fieldsets[1].fields["value" + i] = {
            "type": "number",
            "required": true,
            "className": "col-6",
            "placeholder": "insert",
            "icon": "faRoad",
            "label": "value " + i
        }

        detailConfig.tabs[0].fieldsets[1].fields["traced" + i + "_at"] = {
            "type": "date",
            "required": true,
            "className": "col-6",
            "placeholder": "insert",
            "icon": "faCalendarStar",
            "label": "tracing date " + i
        }
    }

    useEffect(() => {
        loadOne(params.filters?._id);
        loadOneCar(params.car?._id).then(()=> {
            setDrawable(true);
        });
    }, []);

    const [editing, setEditing] = useState(false)

    useEffect(() => {
        if (success && editing) {
            (async () => {
                loadOneCar(params.car?._id);
                try {
                    await params.handleClose(params.uid, item.data)
                } catch (e) { }
            })()
        }
    }, [success]);

    useEffect(() => {
        if (car && typeof car === "object" && !Array.isArray(car) && car.data.hasOwnProperty('legalEntity')) {
            injectToDetail(item?.data, 'car', car.data);
            injectToDetail(item?.data, 'legalEntity', car.data.legalEntity);
            injectToDetail(item?.data, 'traced_at', new Date().toISOString());
            // setDrawable(true);
        }
    }, [car]);


    const handleSave = async (data) => {
        doAction && doAction({ action: wizardAction, model: model ? model : dataProviderName, data: data });
    }

    return (
        !drawable ?   <Backdrop open={!drawable} style={{ backgroundColor: "white" }}>
        <CircularProgress />
    </Backdrop> 
    : <DetailComponent
            values={{ ...item?.data }}
            modelName={modelName}
            detailConfig={detailConfig}
            onEditing={setEditing}
            save={handleSave}
            saving={saving}
            errors={errors}
            errorMessage={errorMessage}
            title={t('New {{itemname}}', { itemname: t(modelExposedName) })}
            canSave={userCapabilities && userCapabilities[dataProviderName + '.create']}
            header={
                <InfoHeader
                    view="wizard"
                    title={t('Create new {{itemname}}', { itemname: t(modelExposedName) })}
                    subtitle={
                        params.traceKmsLast ?
                            t('Fill the basic information to create a new {{itemname}}. Last trace ({{lasttracevalue}}) has been recorded at {{lasttracedate}}', { itemname: t(modelExposedName), lasttracevalue: formatDistance(params.traceKmsLast.value), lasttracedate: formatDate(params.traceKmsLast.traced_at) })
                            :
                            t('Fill the basic information to create a new {{itemname}} that you will be able to edit via the standard interface', { itemname: t(modelExposedName) })
                    }
                />
            }
            {...params}
        />
    )
}

export default CreateOriginal;


