import React from "react"

import { Controller } from "react-hook-form";
import InputTextElement from "./InputTextElement";
import { DrawerButton } from "../Drawer";

const InputDrawerButton = ({ handleSubmit = () => { }, control, editing, item, required, name, label, autoFocus, placeholder, type = "text", hint, icon, haserror, disabled, readonly, className = "", watchfunction = null }) => {

    if (!editing) readonly = true;

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            handleSubmit && handleSubmit();
        }

    }

    return (
        <Controller
            name={name}
            control={control}
            render={({
                field: { onChange, value, name },
                fieldState: { error, invalid },
                formState,
            }) => {


                return  value?.routeName && (
                    <>
                       <DrawerButton 
                        drawerRoute={value?.routeName}
                        params={value?.params}
                        label={label} /> 

                    </>
                )
            }}
        />
    );
};


export default InputDrawerButton;
