import React from 'react';
import { ListItem, ListItemContent, Box } from "@mui/joy";
import { Button } from '../Button';

import { Tag } from '../Snippet';
import { useTranslation } from 'react-i18next'
import { Icon } from '../../assets';
import { useFormatter } from '../../utils';

const RelationshipFileGeneric = ({ k, v, editing, detailInspector = true, handleItemDetail, handleItemRemove }) => {
    const { t } = useTranslation();
    const { formatBytes, formatDate } = useFormatter();

    const printableExtensions = ['JPG', 'JPEG', 'PNG', 'SVG', 'WEBP'];
    const isPrintable = () => {
        const ucExtension = v?.extension && v?.extension.toUpperCase();
        return printableExtensions.includes(ucExtension);
    }

    const apiBasePath =  process.env.REACT_APP_API_ENDPOINT || '';

    return (
        <ListItem key={k} variant="outlined" className={"relationship-element"}>
            <ListItemContent className={"relationship-element-content"}>
                <Box className={"icon-file icon-" + (isPrintable() ? 'preview' : 'type')}>
                    {
                        isPrintable() && (
                            <img src={apiBasePath + v?.url} className="filepreview" />
                        )
                    }
                    {
                        !isPrintable() && (
                            <>
                                <Icon icon="faFile" className="fileicon" />
                                <Box className="extension">{v?.extension}</Box>
                            </>
                        )
                    }
                </Box>
                <Box className="coupled meta">
                    {
                        v.filename ? (
                            <>
                            <span className="label">{v?.filename}</span>
                            <span className="value">{formatBytes(v?.size / 1024)}</span>
                            </>
                        ) : (
                            <Tag color={"danger"} title={t("Missing")}>{t('Missing file')}</Tag>
                        )
                    }
                </Box>
                <Box className="type">{t(v?.type)}</Box>
                {
                    (v?.documentIdentifier != undefined || v?.documentIssuer  != undefined ) && (
                        <Box className="coupled issuer">
                            <span className="label"><Icon icon="faFingerprint" />{v?.documentIdentifier}</span>
                            <span className="value"><Icon icon="faLocationPin" />{v?.documentIssuer}</span>
                        </Box>
                    )
                }
                {
                    (v?.valid_at  != undefined || v?.expire_at != undefined ) && (
                        <Box className="coupled validity">
                            <span className="label"><Icon icon="faCalendarXmark" />{formatDate(v?.expire_at)}</span>
                            <span className="value"><Icon icon="faCalendarHeart" />{formatDate(v?.valid_at)}</span>
                        </Box>
                    )
                }
            </ListItemContent>

            {editing && detailInspector && (<Button onClick={() => handleItemDetail(v)} icon="faPen" variant="plain" />)}
            {(editing && handleItemRemove) && (<Button onClick={() => handleItemRemove(v)} icon="faClose" variant="plain" />)}
        </ListItem>
    )
}

export default RelationshipFileGeneric



