import * as Views from "./views";
import * as Wizards from "./wizards";

const Module = {
    name: "persons",
    defaultRoutePath: "/people/person",
    menuItemScope: "people",
    menuItemName: "people",

    routes: [
        {
            path: "/people/person",
            protected: true,
            children: [
                {
                    index: true,
                    path: "",
                    Component: Views.Default,
                },
                {
                    path: "list",
                    Component: Views.List,
                },
            ],
        },
    ],
    drawerRoutes: [
        {
            path: "list",
            Component: Views.List,
        },
        {
            path: "detail",
            Component: Views.Detail,
        },
        
        {
            path: "create",
            Component: Views.Create,
        },
        {
            path: "carAssociation",
            Component: Wizards.CarAssociation,
        },
        {
            path: "carDissociation",
            Component: Wizards.CarDissociation,
        },
        {
            path: "poolAssociation",
            Component: Wizards.PoolAssociation,
        },
        {
            path: "serviceAssociation",
            Component: Wizards.ServiceAssociation,
        },
        {
            path: "serviceDissociation",
            Component: Wizards.ServiceDissociation,
        },
        {
            path: "employmentAssociation",
            Component: Wizards.EmploymentAssociation,
        },
        {
            path: "employmentDissociation",
            Component: Wizards.EmploymentDissociation,
        },
        {
            path: "drivingLicenceAssociation",
            Component: Wizards.DrivingLicenceAssociation,
        },
        {
            path: "personalPictureAssociation",
            Component: Wizards.PersonalPictureAssociation,
        },
        {
            path: "genericFileAssociation",
            Component: Wizards.GenericFileAssociation,
        },
        {
            path: "configuratorManagement",
            Component: Wizards.ConfiguratorManagement,
        },
        
    ],
}

export default Module;
