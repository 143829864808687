import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDrawer } from 'src/mtska-frontend-app-component';
import { useDataProvider } from 'src/mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const dataProviderName = 'persons/person';
const wizardName = 'Car Configuration Access Management';
const wizardObject = 'Car';
const wizardAction = 'configuratorManagement';

const ConfiguratorManagement = (params) => {
    const { t } = useTranslation();
    const [segmentList, setSegmentList] = useState();
    const { userCapabilities, existingModules } = useContext(AppContext);

    const { items: segments, loadAll: loadAllSegments } = useDataProvider('infocar/getAvailableSegments');

    // DATA PROVIDER
    const {
        item,
        errorMessage,
        errors,
        saving,
        doAction,
    } = useDataProvider(dataProviderName, params.multipart);

    useEffect(() => {
        loadAllSegments().then((segments) => {
            let sl = segments.items.map((segment) => {
                return {
                    "label": segment.Descrizione,
                    "value": segment.Descrizione,//segment.Codice
                }
            });
            setSegmentList(sl);
        });
    }, []);

    const modelName = existingModules.modelName
    
    const detailConfig = (params?.person?.employment?.legalEntity?.configurator !== 'infocarfleet') ? {
        "tabs": [
            {
                "name": "configuration",
                "fieldsets": [
                    {
                        "name": "configuration",
                        "fields": {
                            "configuration": {
                                "type": "sectiontitle",
                                "className": "col-12"
                            },
                            "configuration_error": {
                                "type": "sectionparagraph",
                                "paragraph": "Please add relevant information to the LegalEntity in order to allow the configuration process",
                                "className": "col-12"
                            }
                        }
                    }
                ]
            }
        ]
    } : {
        "tabs": [
            {
                "name": "configuration",
                "fieldsets": [
                    {
                        "name": "configuration",
                        "fields": {
                            "configuration": {
                                "type": "sectiontitle",
                                "className": "col-12"
                            },
                            "fleetConfigurationActive": {
                                "type": "select",
                                "required": true,
                                "options": [
                                    {
                                        "label": "true",
                                        "value": "true"
                                    },
                                    {
                                        "label": "false",
                                        "value": "false"
                                    }
                                ],
                                "className": "col-4",
                                "icon": "faToggle"
                            },
                            "fleetConfigurationLinkValid_at": {
                                "type": "date",
                                "required": true,
                                "className": "col-4",
                                "icon": "faCalendarHeart"
                            },
                            "fleetConfigurationLinkExpire_at": {
                                "type": "date",
                                "required": true,
                                "className": "col-4",
                                "icon": "faCalendarXmark"
                            },
                            "segment": {
                                "type": "select",
                                "required": true,
                                "options": segmentList,
                                "className": "col-12",
                                "icon": "faCircleX"
                            },
                            "fleetDriverCode": {
                                "type": "text",
                                "required": true,
                                "readonly": true,
                                "className": "col-4",
                                "icon": "faPerson"
                            },
                            "email": {
                                "type": "email",
                                "required": true,
                                "readonly": true,
                                "className": "col-4",
                                "icon": "faMessage"
                            },
                            "fleetConfigurationQuantityUnlimited": {
                                "type": "select",
                                "required": true,
                                "options": [
                                    {
                                        "label": "true",
                                        "value": "true"
                                    },
                                    {
                                        "label": "false",
                                        "value": "false"
                                    }
                                ],
                                "className": "col-4",
                                "icon": "faToggle"
                            },
                            "fleetConfigurationLink": {
                                "type": "text",
                                "required": true,
                                "readonly": true,
                                "className": "col-12",
                                "icon": "faGlobe"
                            },
                        }
                    }
                ]
            }
        ],
        "validation": []
    }

    const handleSave = (data) => {
        doAction({ action: wizardAction, model: dataProviderName, data: data }).then((data) => params.handleClose(params.uid, data.item.data)).catch(() => console.error('catch handleSave'));
    }

    return (
        segmentList && <DetailComponent
            values={{ 
                "id": params?.person?._id, 
                "email": params?.person?.email, 
                "segment":params?.person?.employment?.segment,
                "fleetDriverCode": params?.person?.firstname + params?.person?.lastname + params?.person?._id,
                "fleetConfigurationLink":params?.person?.employment?.fleetConfigurationLink,
                "fleetConfigurationActive":params?.person?.employment?.fleetConfigurationActive,
                "fleetConfigurationQuantityUnlimited":params?.person?.employment?.fleetConfigurationQuantityUnlimited,
                "fleetConfigurationLinkValid_at":params?.person?.employment?.fleetConfigurationLinkValid_at,
                "fleetConfigurationLinkExpire_at":params?.person?.employment?.fleetConfigurationLinkExpire_at
            }}
            modelName={modelName}
            detailConfig={detailConfig}
            save={handleSave}
            saving={saving}
            errors={errors}
            errorMessage={(params?.person?.employment?.legalEntity?.configurator !== 'infocarfleet') ? t('LegalEntity not allowed to configure') : errorMessage}
            title={t(wizardName)}
            canSave={userCapabilities && userCapabilities[dataProviderName + '.' + wizardAction.toLowerCase()]}
            header={
                <InfoHeader
                    view="wizard"
                    title={t(wizardName)}
                    subtitle={t('Manage the access to Car Configurator for {{firstname}} {{lastname}}', { wizardObject: wizardObject, firstname: params?.person?.firstname, lastname: params?.person?.lastname })}
                />
            }
            {...params}
        />
    );
}

export default ConfiguratorManagement;


