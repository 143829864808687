import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDrawer } from 'src/mtska-frontend-app-component';
import { useDataProvider } from 'src/mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';
import { CircularProgress } from '@mui/joy';
import {  Backdrop } from "@mui/material"


const dataProviderName = 'documents/document';
const wizardName = 'Delivery Report association';
const wizardObject = 'Delivery Report';
const wizardAction = 'deliveryReportAssociation';

const DeliveryReportAssociation = (params) => {

    const { t } = useTranslation();
    const { close } = useDrawer();
    const { userCapabilities, existingModules } = useContext(AppContext);
    const [drawable, setDrawable] = useState(false);





    // DATA PROVIDER
    const {
        item,
        save,
        errorMessage,
        errors,
        saving,
        success
    } = useDataProvider(dataProviderName, true);

    const { item: car, loadOne: loadOneCar } = useDataProvider('cars/car');

    const modelName = existingModules.modelName
    const detailConfig = {
        "tabs": [
            {
                "name": "document",
                "fieldsets": [
                    {
                        "name": "document",
                        "fields": {
                            "Carica il Verbale di presa in consegna del veicolo": {
                                "type": "sectiontitle",
                                "className": "col-12",
                            },
                            "file": {
                                "type": "dropzone",
                                "className": "col-12",
                                "icon": "faFile"
                            },
                        }
                    }
                ]
            }
        ],
        "validation": []
    }


    useEffect(() => {
        loadOneCar(params.carId).then(() => {
            setDrawable(true);
        });
    }, []);

    // DOCUMENT ****NON USA**** QUESTO PROCESSO DI CREAZIONE TRAMITE JOB
    // 
    // const handleSave = (data) => {
    //     doAction({action: wizardAction, model: dataProviderName, data: data}).then((data) => params.handleClose(params.uid, data.item.data)).catch(() => console.error('catch handleSave'));
    // }

    const [editing, setEditing] = useState(false)


    useEffect(() => {
        if (success && editing) {
            loadOneCar(params.carId).then(() => {
                close();
            });
        }
    }, [success]);

    const handleSave = async (data) => {
        save && save({ ...data, filters: params.filters })
    }

    return (
        !drawable ?  
            <Backdrop open={!drawable} style={{ backgroundColor: "white" }}>
                    <CircularProgress />
                </Backdrop>
        : <DetailComponent
            values={{
                "car_id": params.carId,
                "type": "deliveryReport",
                "visibleToDriver": "true",
                "name": t("deliveryReport") + (car?.plate && car.plate),
            }}
            modelName={modelName}
            detailConfig={detailConfig}
            onEditing={setEditing}
            save={handleSave}
            saving={saving}
            errors={errors}
            errorMessage={errorMessage}
            title={t(wizardName)}
            canSave={userCapabilities && userCapabilities[dataProviderName + '.' + wizardAction.toLowerCase()]}
            header={
                <>
                    <InfoHeader
                        hideContractBox={true}
                        view="vehicle"
                        item={car.data}
                        title={t(wizardName)}
                        subtitle={t('Add a {{wizardObject}} to {{plate}}', { wizardObject: wizardObject, plate: car?.plate })}
                    />
                                    
                </>
            }
            {...params}
        />
    )
}

export default DeliveryReportAssociation;


